import React, {Fragment, useState, useEffect, cloneElement} from 'react'
import T from 'prop-types'
import {flatten} from 'flat'
import isEmpty from 'lodash/isEmpty'
import DatePicker, {registerLocale} from 'react-datepicker'
import ru from 'date-fns/locale/ru'

registerLocale('ru', ru)

import Input from 'app/Elements/Input'
import Icon from 'app/Elements/Icon'
import Button from 'app/Elements/Button'
import SmartLink from 'app/Elements/SmartLink'
import UnifiedMap, {PROVIDER_GOOGLE, PROVIDER_YANDEX} from 'app/Elements/UnifiedMap'
import Map, {getSearchTest} from "app/Elements/Map"
import {getSearchText} from "app/Elements/YaMap"
import Accordion, {AccordionGroup} from 'app/Elements/Accordion'
import {Dialog} from 'app/Elements/Floating'

import InfoMessage from 'app/Components/InfoMessage'
import Checkbox from 'app/Elements/Checkbox'
import Attention from 'app/Components/Attention'
import Attachment from 'app/Components/Attachment'
import DropDownListStreet from 'app/Elements/DropDownListStreet'
import AddressError from 'app/Components/AddressError'

import pluralizeWord from 'helpers/strings/pluralizeWord'

import {ModalWrapper} from 'app/Layout/Modal'

import moment, {getMinTimeForPreOrder, getMaxTimeForPreOrder} from 'helpers/moment'
import {toPhonePattern, phonePlaceholder} from 'helpers/form/mask'
import {uploadFiles} from 'helpers/order'

import isServer from 'helpers/isServer'
import cap from 'helpers/strings/capitalizeFirstLetter'
import joinValues from 'helpers/strings/joinValues'
import {getPriceFormatted} from 'helpers/price'
import {calcCost} from 'helpers/order'

import sberbank from 'app/Assets/sberbank.png'
import InfoIcon from 'app/Assets/Info'

import cx from 'classnames'
import css from './index.styl'
import './react-datepicker.styl'

const PHONE_LENGTH = 11
const MASTER_GEO_MARKERS_INTERVAL = 5000

let masterGeoMarkersInterval = null

const errors = {
  SHORT_PHONE: 'Введите, пожалуйста, номер телефона полностью',
  INVALID_PHONE: 'Неправильно указан номер',
  NO_DESCRIPTION: 'Опишите, пожалуйста, вашу проблему',
  NO_STREET: 'Укажите, пожалуйста, улицу',
  NO_HOUSE: 'Добавьте, пожалуйста, номер дома',
  NO_APARTMENTS: 'Добавьте, пожалуйста, номер квартиры',
  NO_TIME: 'Укажите, пожалуйста, время приезда мастера',
  NO_FIRSTNAME: 'Введите, пожалуйста, имя',
  NO_LASTNAME: 'Введите, пожалуйста, фамилию',
  NO_PHONE: 'Введите, пожалуйста, номер телефона',
  EMPTY_DESCRIPTION: 'Укажите, что необходимо сделать',
  NO_ISAGREE: 'Дайте, пожалуйста, свое согласие',
  INVALID_EMAIL: 'Некорректный адрес электронной почты для чека',
}

const serviceType = {
  settings: 'REPAIR',
  shower: 'SETUP',
  shelve: 'ASSEMBLE',
  cargo: 'REARRANGE',
  hover: 'CLEAN',
  plant: 'PLANT',
}

async function updateTime(orderMode, state, props) {
  const {changeTime, getOrderCheckCommandant, changeOrderTest, changeIsCommandant} = props
  const {modalDate, modalTime} = state

  const time = `${modalDate}T${modalTime}:00Z`

  if (orderMode === 'now' || orderMode === '') {
    changeIsCommandant(false)
    changeOrderTest(false)
    changeTime(null)
  } else {
    const answer = await getOrderCheckCommandant(time)

    if (answer.result === false) {
      changeIsCommandant(false)
    }

    changeTime(time)
  }
}

function updateSearchText(state, props) {
  const provider = PROVIDER_YANDEX // actually must come from props
  const {handleSearchText, isLocalStorageLoad} = state
  let nextSearchText = ''

  if (provider === PROVIDER_YANDEX) {
    nextSearchText = getSearchText(state)
  } else if (provider === PROVIDER_GOOGLE) {
    nextSearchText = getSearchTest(state)
  }

  isLocalStorageLoad && handleSearchText(nextSearchText)
}

function updateOrder(state, props) {
  const {imageUrls, title, newOrderTitle, newOrderType, updateOrder: changeOrder, isCommandant} = props

  const {
    firstName,
    lastName,
    email,
    city,
    street,
    house,
    building,
    housing,
    entrance,
    entranceCode,
    floor,
    apartments,
    description,
    coords: {lon, lat} = {},
    address,
    slug,
  } = state

  const images = typeof imageUrls === 'string' ? [imageUrls] : imageUrls
  const orderTitle = title || newOrderTitle || ''

  changeOrder(
    // killEmptyProps(
    flatten(
      {
        client_first_name: firstName,
        client_last_name: lastName,
        client_email: email,
        // time: null,
        title: orderTitle,
        description: description,
        geo_city: city || 'Москва',
        geo_street: street,
        geo_address: address,
        geo_house: house,
        geo_building: building,
        geo_housing: housing,
        geo_entrance: entrance,
        geo_entrance_code: entranceCode,
        geo_floor: floor,
        geo_apartments: apartments,
        geo_lon: lon || '54.7308220375855300',
        geo_lat: lat || '20.5319310513769220',
        image_urls: images,
        service_type: serviceType[newOrderType],
        is_commandant: isCommandant,
        slug,
      },
      {safe: true},
    ),
    // )
  )
}

function clearOrder(state, props) {
  const {id, changeTime, changeImageUrls} = props

  if (id) {
    changeTime(null)
    changeImageUrls([])
  }
}

function toggleTimeSelectorModal(state, props) {
  return function timeSelectorToggler() {
    const {isModalOpened, handleIsModalOpened} = state

    handleIsModalOpened(!isModalOpened)
  }
}

function changeOrderMode(nextMode, state, props) {
  const {handleOrderMode} = state

  handleOrderMode(nextMode)

  toggleTimeSelectorModal(state, props)()
}

function handleMapVisibility(state, props) {
  return function mapVisibilityHandler(e) {
    const {handleIsMap, isMap} = state
    handleIsMap(!isMap)
  }
}

function handleAddress(state) {
  return function getAddressFromMap(data, searchText) {
    const {
      city,
      street,
      house,
      lon,
      lat,
      address,
      housing,
      building,
      entranceCode,
      floor,
      apartments,
      entrance,
    } = data

    const {
      handleCity,
      handleStreet,
      handleHouse,
      handleCoords,
      handleAddress,
      handleHousing,
      handleBuilding,
      handleEntranceCode,
      handleFloor,
      handleApartments,
      handleEntrance,
    } = state

    // city && handleCity(city)
    // street && handleStreet(street)
    handleCity(city)
    handleStreet(street)

    if (state.house !== data.house) {
      handleHouse(house)
      handleHousing('')
      handleBuilding('')
    }

    handleEntrance && handleEntrance(entrance || '')

    lon && lat && handleCoords({lon, lat})
    address && handleAddress(address)

    // if (housing) {
    //   handleHousing(housing)
    // }
    //
    // if (building) {
    //   handleBuilding(building)
    // }

    handleHousing(housing)
    handleBuilding(building)
  }
}

function handleAuthRequest(state, props) {
  return async function authRequestHandler() {
    const {requestAuth} = props
    const {
      phone,
      // firstName,
      // lastName,
      handleWasCodeSent,
      phoneError,
      handleIsLoading,
      handleIsValidationButton,
    } = state

    const triggerErrors = validateForm(state, props)

    if (triggerErrors.length !== 0) {
      handleIsValidationButton(true)
      return triggerErrors.map((f) => f())
    } else {
      handleIsValidationButton(false)
      handleIsLoading(true)
      let isRequestError = false

      await requestAuth({
        phone,
        // first_name: firstName,
        // last_name: lastName,
      }).catch((error) => {
        handleIsLoading(false)

        isRequestError = true
        if (phoneError) return

        if ((error.extra || {}).phone) {
          handleIsValidationButton(true)
          return handleError('phone', state)(errors.INVALID_PHONE)
        }

        if ((error.extra || {}).detail) {
          return handleError('phone', state)(error.extra.detail)
        } else {
          return handleError('phone', state)(error.message)
        }
      })

      handleIsLoading(false)
      !isRequestError && handleWasCodeSent(true)
    }
  }
}

function recordLocalStorage(state) {
  const {city, street, house, building, housing, entrance, entranceCode, floor, apartments, firstName, lastName, email, phone} = state

  localStorage.setItem(
    'ezhek-user-state',
    JSON.stringify({
      city,
      street,
      house,
      building,
      housing,
      entrance,
      entranceCode,
      floor,
      apartments,
      firstName,
      lastName,
      email,
      phone,
    }),
  )
}

function handleOrderMaster(state, props) {
  return async function masterOrderHandler() {
    const {
      phone,
      code,
      handleIsLoading,
      handleIsValidationButton,
      orderMode,
      description = '',
      street = '',
      house = '',
      building = '',
      housing = '',
      entrance = '',
      entranceCode = '',
      floor = '',
      apartments = '',
    } = state
    const {confirmAuth, goToPage, placeOrder, authToken} = props

    const triggerErrors = validateForm(state, props)

    if (triggerErrors.length !== 0) {
      handleIsValidationButton(true)
      return triggerErrors.map((f) => f())
    } else {
      handleIsLoading(true)
      recordLocalStorage(state)
      let isRequestError = false

      const {token} = authToken
        ? {token: authToken}
        : (await confirmAuth({code, phone}).catch((error) => {
        isRequestError = true
        handleIsLoading(false)

        return
      })) || {}

      if (token) {
        const trimmedOrderFields = {
          description: description.trim(),
          geo_street: street.trim(),
          geo_house: house.trim(),
          geo_building: building.trim(),
          geo_housing: housing.trim(),
          geo_entrance: entrance.trim(),
          geo_entranceCode: entranceCode.trim(),
          geo_floor: floor.trim(),
          geo_apartments: apartments.trim(),
        }
        const {orderId} =
        (await placeOrder(trimmedOrderFields)
          .then((data) => {
            if (data.formUrl && !isServer) {
              return setTimeout((window.location.href = data.formUrl), 1500)
            }
          })
          .catch((error) => {
            isRequestError = true
            handleIsLoading(false)

            if ((error.extra || {}).phone) {
              return handleError('order', state)(errors.EMPTY_DESCRIPTION)
            }
            if ((error.extra || {}).detail) {
              return handleError('order', state)(error.extra.detail)
            } else {
              return handleError('order', state)(error.message)
            }
          })) || {}

        handleIsLoading(false)
        // const nextStepUrl = getNextStepUrl(state, props, '/payment')

        // if (!isRequestError && token && orderId) {

        //   // return goToPage(nextStepUrl)
        // }
      }
    }
  }
}

function handleInput(key, state) {
  return function handler(nextValue) {
    const inputHandler = state[`handle${cap(key)}`]
    const isError = state[`${key}Error`]
    const errorHandler = state[`handle${cap(key)}Error`]

    if (isError && errorHandler) errorHandler('')

    if (key === 'street') {
      state[`handleHouse`]('')
      state[`handleHousing`]('')
      state[`handleBuilding`]('')
    }

    if (key === 'house') {
      state[`handleHousing`]('')
      state[`handleBuilding`]('')
    }

    if (key === 'phone') inputHandler(toPhonePattern(nextValue))
    else inputHandler(nextValue)
  }
}

function handleError(key, state) {
  return function handler(nextError) {
    const isError = state[`${key}Error`]
    const errorHandler = state[`handle${cap(key)}Error`]

    if (isError || !errorHandler) return
    errorHandler(nextError)
  }
}

function getControlProps(key, state) {
  return {
    value: state[key],
    handleInput: handleInput(key, state),
    error: state[`${key}Error`],
  }
}

function checkErrorRules(state, props, {keys, getValidationKeys}) {
  const errorFuncs = []
  let index = 0
  keys.forEach((inputKey) => {
    const errorHandler = handleError(inputKey, state)

    const {errorRules} = formValues[inputKey]
    const inputValidationKeys =
      typeof getValidationKeys === 'function' ? getValidationKeys(inputKey) : Object.keys(errorRules)

    inputValidationKeys.forEach((validationKey) => {
      const inputError = errorRules[validationKey](state, props, {
        key: inputKey,
      })

      if (inputError) {
        errorFuncs.push(
          index === 0 && inputKey !== 'isAgree'
            ? () => {
              document.getElementById(inputKey).focus()
              return errorHandler(inputError)
            }
            : () => errorHandler(inputError),
        )
        index++
      }
    })
  })

  return errorFuncs
}

function validateForm(state, props) {
  const {isLogged} = state
  let errorFuncs = []
  let set = new Set()

  Object.keys(formValues).map((key) => !isEmpty(formValues[key].errorRules) && set.add(key))

  return (errorFuncs = errorFuncs.concat(checkErrorRules(state, props, {keys: set})))
}

const validators = {
  isEmpty: function (state, props, {key}) {
    const value = state[key]
    if (!value || !value.trim()) return errors[`NO_${key.toUpperCase()}`]
  },
  isValidPhone: function (state, props, params) {
    const key = 'phone'
    const value = state[key]
    if (value.length < PHONE_LENGTH) return errors['SHORT_PHONE']
  },
  isValidEmail: function (state, props, params) {
    const key = 'email'
    const value = state[key]

    if (value.trim() && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))) {
      return errors['INVALID_EMAIL']
    }
  },
  isTrue: function (state, props, {key}) {
    const value = state[key]
    if (!value) return errors[`NO_${key.toUpperCase()}`]
  },
}

const formValues = {
  street: {
    initialState: '',
    errorRules: {
      // isEmpty: validators.isEmpty,
    },
  },
  house: {
    initialState: '',
    errorRules: {
      isEmpty: validators.isEmpty,
    },
  },
  apartments: {
    initialState: '',
    errorRules: {
      isEmpty: validators.isEmpty,
    },
  },
  description: {
    initialState: '',
    // errorRules: {
    //   isEmpty: validators.isEmpty,
    // },
  },
  firstName: {
    initialState: '',
    errorRules: {
      isEmpty: validators.isEmpty,
    },
  },
  lastName: {
    initialState: '',
    errorRules: {},
  },
  email: {
    initialState: '',
    errorRules: {
      isValidEmail: validators.isValidEmail,
    },
  },
  city: {
    initialState: '',
    errorRules: {},
  },

  coords: {
    initialState: '',
    errorRules: {},
  },
  address: {
    initialState: '',
    errorRules: {},
  },

  building: {
    initialState: '',
    errorRules: {},
  },
  housing: {
    initialState: '',
    errorRules: {},
  },
  entrance: {
    initialState: '',
    errorRules: {},
  },
  entranceCode: {
    initialState: '',
    errorRules: {},
  },
  floor: {
    initialState: '',
    errorRules: {},
  },

  modalDate: {
    initialState: moment().format('YYYY-MM-DD'),
    errorRules: {},
  },
  modalTime: {
    initialState: getMinTimeForPreOrder().format('HH:mm'),
    errorRules: {},
  },

  phone: {
    initialState: '',
    errorRules: {
      isEmpty: validators.isEmpty,
      isValidPhone: validators.isValidPhone,
    },
  },

  searchText: {
    initialState: '',
    errorRules: {},
  },
  isAgree: {
    initialState: false,
    errorRules: {
      isEmpty: validators.isTrue,
    },
  },
  isLocalStorageLoad: {
    initialState: false,
    errorRules: {},
  },
  slug: {
    initialState: ({newOrderSlug}) => newOrderSlug,
    errorRules: {},
  }
}

function useForm(props) {
  const state = {}
  const handlers = {}

  Object.keys(formValues).forEach((name) => {
    const {initialState, errorRules = {}} = formValues[name]

    const stateField = useState(typeof initialState === 'function' ? initialState(props) : initialState)
    state[name] = stateField[0]
    handlers[`handle${cap(name)}`] = stateField[1]

    if (Object.keys(errorRules).length > 0) {
      const stateErrorField = useState('')
      state[`${name}Error`] = stateErrorField[0]
      state[`handle${cap(name)}Error`] = stateErrorField[1]
    }
  })

  const localState = {
    ...state,
    ...handlers,
  }

  const {
    firstName,
    lastName,
    email,
    description,
    city,
    street,
    house,
    building,
    housing,
    entrance,
    entranceCode,
    floor,
    apartments,
    address,
    coords,
    isLocalStorageLoad,
  } = localState

  useEffect(() => {
    clearOrder(localState, props)
  })

  useEffect(() => {
    updateOrder(localState, props)
  }, [firstName, lastName, email, description, city, street, house, building, housing, entrance, entranceCode, floor, apartments, address, coords, isLocalStorageLoad])

  useEffect(() => {
    updateSearchText(localState, props)
  }, [street, house, building, housing, floor, entrance, apartments, isLocalStorageLoad])

  return [state, handlers]
}

function useControls(props) {
  const [isMap, handleIsMap] = useState(true)

  const [isModalOpened, handleIsModalOpened] = useState(false)
  const [orderMode, handleOrderMode] = useState('')

  const [wasCodeSent, handleWasCodeSent] = useState(false)
  const [code, handleCode] = useState('')
  const [isLoading, handleIsLoading] = useState(false)
  const [isImageLoading, handleIsImageLoading] = useState(false)

  const [isListStreet, handleListStreet] = useState(false)
  const [isAddressError, handleAddressError] = useState(false)
  const [isAuthRequestDisabled, handleIsAuthRequestDisabled] = useState(true)
  const [isLogged, handleIsLogged] = useState(false)
  const [isValidationButton, handleIsValidationButton] = useState(false)
  const [isModalTestOrder, handleIsModalTestOrder] = useState(false)

  const state = {
    isMap,
    isModalOpened,
    orderMode,
    wasCodeSent,
    code,
    isLoading,
    isImageLoading,
    isListStreet,
    isAddressError,
    isAuthRequestDisabled,
    isLogged,
    isValidationButton,
    isModalTestOrder,
  }

  const handlers = {
    handleIsMap,
    handleIsModalOpened,
    handleOrderMode,
    handleWasCodeSent,
    handleCode,
    handleIsLoading,
    handleIsImageLoading,
    handleListStreet,
    handleAddressError,
    handleIsAuthRequestDisabled,
    handleIsLogged,
    handleIsValidationButton,
    handleIsModalTestOrder,
  }

  return [state, handlers]
}

function useOrder(props) {
  const {getMasterGeoMarkers} = props
  const [formState, formHandlers] = useForm(props)
  const [controlsState, controlsHandlers] = useControls(props)

  const [mastersFirstLoad, handleMastersFirstLoad] = useState(true)
  const [mastersTotalCount, handleMastersTotalCount] = useState(0)
  const [mastersText, handleMastersText] = useState('Загружаем список мастеров...')

  const state = {...formState, ...controlsState, mastersText}
  const handlers = {...formHandlers, ...controlsHandlers}

  const {isLocalStorageLoad} = formState
  const {street, house, apartments, description, firstName, lastName, email, phone, isAgree, isLogged, orderMode} = state
  const {handleIsAuthRequestDisabled, handleIsLogged, handleIsModalTestOrder} = handlers
  const {authToken, orderTest} = props

  useEffect(() => {
    if (!isLocalStorageLoad) {
      fillOrderFields(handlers)
    }
  }, [isLocalStorageLoad])

  useEffect(() => {
    if (authToken) {
      handleIsLogged(true)
    } else {
      handleIsLogged(false)
    }
  }, [authToken])

  useEffect(
    () =>
      (!isLogged ? isAgree : true) && street !== '' && house !== '' && description !== '' && firstName !== '' && lastName !== '' && email !== '' && phone
        ? handleIsAuthRequestDisabled(false)
        : handleIsAuthRequestDisabled(true),
    [isAgree, street, house, apartments, description, firstName, lastName, email, phone],
  )

  useEffect(() => {
    if (orderTest && orderMode === 'time') {
      handleIsModalTestOrder(true)
    }
  }, [orderTest, orderMode])

  useEffect(() => {
    // Mount
    getMasterGeoMarkers()
      .then(({masters = [], totalMastersCount = 0} = {}) => {
        handleMastersFirstLoad(false)
        handleMastersTotalCount(totalMastersCount)
      })
    masterGeoMarkersInterval = setInterval(getMasterGeoMarkers, MASTER_GEO_MARKERS_INTERVAL)

    // Unmount
    return () => clearInterval(masterGeoMarkersInterval)
  }, [])

  useEffect(() => {
    if (!mastersFirstLoad) {
      if (mastersTotalCount === 0) {
        handleMastersText('Мастера поблизости от Вас не найдены.')
      } else {
        handleMastersText(`Поблизости от Вас ${mastersTotalCount} ${pluralizeWord(mastersTotalCount, 'мастер.', 'мастера.', 'мастеров.')}`)
      }
    }
  }, [mastersTotalCount, mastersFirstLoad])

  return [state, handlers]
}

function fillOrderFields(handlers) {
  const updateState = JSON.parse(localStorage.getItem('ezhek-user-state'))
  const {handleIsLocalStorageLoad} = handlers

  if (updateState) {
    Object.keys(updateState).forEach((name) => {
      if (name === 'phone') {
        handlers[`handle${cap(name)}`](toPhonePattern(updateState[`${name}`]))
      } else {
        handlers[`handle${cap(name)}`](updateState[`${name}`])
      }
    })
    handleIsLocalStorageLoad(true)
  }
  handleIsLocalStorageLoad(true)
}

// dumb
function renderInputFormAddress(state, props) {
  const {listStreet} = props
  const {isListStreet, handleListStreet, handleStreet, isAddressError, house} = state

  return (
    <Fragment>
      <Input
        className={css.wideInputWrap}
        inputClassName={cx(css.wideInput, css.wideInputCity)}
        label={'Город'}
        name={'city'}
        {...getControlProps('city', state)}
        // isRequiredLabel
        id={'city'}        
      />
      <Input
        className={css.wideInputWrap}
        inputClassName={css.wideInput}
        label={'Улица'}
        name={'street'}
        onFocus={() => handleListStreet(true)}
        {...getControlProps('street', state)}
        // isRequiredLabel
        id={'street'}
      />
      <DropDownListStreet
        listStreet={listStreet}
        handleStreet={handleStreet}
        handleOpen={handleListStreet}
        isOpen={isListStreet}
      />
      <div className={cx(css.accomodation, css.topInput)}>
        <Input
          className={css.inputWrap}
          inputClassName={css.input}
          label={'Дом'}
          name={'house'}
          {...getControlProps('house', state)}
          isRequiredLabel
          id={'house'}
        />
        <Input
          className={css.inputWrap}
          inputClassName={css.input}
          label={'Строение'}
          name={'building'}
          {...getControlProps('building', state)}
        />
        <Input
          className={css.inputWrap}
          inputClassName={css.input}
          label={'Корпус'}
          name={'housing'}
          {...getControlProps('housing', state)}
        />
        <Input
          className={css.inputWrap}
          inputClassName={css.input}
          label={'Подъезд'}
          name={'entrance'}
          {...getControlProps('entrance', state)}
        />
      </div>
      <div className={cx(css.accomodation, css.downInput)}>
        <Input
          className={css.inputWrap}
          inputClassName={css.input}
          label={'Код'}
          name={'entranceCode'}
          {...getControlProps('entranceCode', state)}
        />
        <Input
          isNumberOnly
          className={cx(css.inputWrap)}
          inputClassName={cx(css.input)}
          label={'Этаж'}
          name={'floor'}
          {...getControlProps('floor', state)}
        />
        <Input
          className={cx(css.inputWrap, css.inputAppartamentWrap)}
          inputClassName={cx(css.input, css.inputAppartamentWrap)}
          label={'Квартира'}
          name={'apartments'}
          {...getControlProps('apartments', state)}
          isRequiredLabel
          id={'apartments'}
        />
        <div className={cx(css.messageRequiredFields, css.messageRequiredFieldsTop)}>* — обязательно к заполнению (если у вас частный дом, введите квартиру номер 1)</div>
        <AddressError isAddressError={isAddressError && house}/>
      </div>
    </Fragment>
  )
}

function renderOrderModeSection(state, props) {
  const {orderMode, modalDate, modalTime} = state
  const dateAndTime = `${modalDate}T${modalTime}`

  return (
    <SmartLink isUnderlined onClick={toggleTimeSelectorModal(state, props)} className={css.callTimeNow}>
      {orderMode === 'time' ? moment.utc(dateAndTime).format('DD.MM.YYYY, HH:mm') : 'Сегодня'}
    </SmartLink>
  )
}

function renderTimeSelectorModal(state, props) {
  const changeDate = (data, name) => {
    state[`handle${cap(name)}`](moment(data).format(name === 'modalDate' ? 'YYYY-MM-DD' : 'HH:mm'))
  }

  const curDate = moment(`${state['modalDate']}T00:00:00.000`)
  const minDate = moment(`${moment().format('YYYY-MM-DD')}T00:00:00.000`).add(1, 'd')

  let curTime = moment(`${state['modalDate']}T${state['modalTime']}:00.000`)

  const minTime = getMinTimeForPreOrder(curDate)
  const maxTime = getMaxTimeForPreOrder(curDate)

  if (curTime < minTime) {
    curTime = minTime

    // обновление времени в стейте, когда открыто модальное окно
    changeDate(curTime, 'modalTime')
  }

  if (curTime > maxTime) {
    curDate.add(1, 'd')
    minDate.add(1, 'd')

    // обновление даты в стейте, когда открыто модальное окно
    changeDate(curDate, 'modalDate')
  }

  return (
    <ModalWrapper
      shouldUseOffset
      name={'timeSelectorModal'}
      onCloseClick={toggleTimeSelectorModal(state, props)}
      title={'Время вызова мастера'}
      titleCl={css.timeSelectorModalTitle}
      modalCl={css.timeSelectorModal}
      iconClose
    >
      <div className={css.dateSelectorInputs}>
        <div className={css.divInputData}>
          <DatePicker
            customInput={
              <Input label={'Дата'} className={css.dateInput} handleInput={date => changeDate(date, 'modalDate')}/>
            }
            onChange={(e) => changeDate(e, 'modalDate')}
            selected={curDate.toDate()}
            minDate={minDate.toDate()}
            locale='ru'
            dateFormat='dd.MM.yyyy'
          />
        </div>
        <div className={css.divInputTime}>
          <DatePicker
            customInput={
              <Input label={'Время'} className={css.timeInput} handleInput={date => changeDate(date, 'modalTime')}/>
            }
            onChange={(e) => changeDate(e, 'modalTime')}
            selected={curTime.toDate()}
            minTime={minTime.toDate()}
            maxTime={maxTime.toDate()}
            locale='ru'
            showTimeSelect
            showTimeSelectOnly
            timeFormat='HH:mm'
            timeIntervals={30}
            dateFormat='HH:mm'
            timeCaption='Время'
          />
        </div>
      </div>
      <div className={css.dateSelectorButtons}>
        <Button
          theme={'orange'}
          className={cx(css.dateButton, css.nowButton)}
          onClick={() => {
            changeOrderMode('now', state, props)
            updateTime('now', state, props)
          }}
        >
          Сегодня
        </Button>
        <Button
          theme={'green'}
          className={css.dateButton}
          onClick={() => {
            changeOrderMode('time', state, props)
            updateTime('time', state, props)
          }}
        >
          Указать
        </Button>
      </div>
    </ModalWrapper>
  )
}

function renderWasCodeSentSection(state, props) {
  const {confirmationCode, orderError, authError, isCommandant, orderTest} = props
  const {
    code,
    handleCode,
    isLoading,
    isLogged,
    isAuthRequestDisabled,
    isValidationButton,
    handleIsModalTestOrder,
  } = state

  const isOrderMasterDisabled = isLogged ? isAuthRequestDisabled : (code || '').length !== 4

  return (
    <Fragment>
      {!isLogged && (
        <div className={css.code}>
          <div className={css.codeLabel}>Введите код</div>
          <Input className={css.codeWrap} inputClassName={css.codeInput} value={code} handleInput={handleCode}/>
        </div>
      )}

      <div className={cx(css.notice, css.hint)}>
        {!isLogged && (confirmationCode && `Подсказка: ${confirmationCode}`)}
        <div className={cx(css.errorSms)}>
          {!!authError && 'Неверный код '}
          {!!((orderError || {}).extra || {}).description && 'Укажите, что необходимо сделать '}
          {!!((orderError || {}).extra || {}).geo_street && 'Укажите улицу '}
          {!!((orderError || {}).extra || {}).geo_apartments && 'Укажите квартиру '}
        </div>
      </div>
      {orderTest && (
        <Checkbox
          name={'isCommandant'}
          checked={isCommandant}
          handleChange={() => handleIsModalTestOrder(true)}
          className={css.testOrderCheckbox}
          //disabled={wasCodeSent}
          label={<div>Контрольный заказ</div>}
        />
      )}
      <Button
        theme={'green'}
        // disabled={isOrderMasterDisabled}
        className={css.callMasterButton}
        onClick={handleOrderMaster(state, props)}
        isBusy={isLoading}
      >
        Вызвать мастера
      </Button>
      {isLogged && isValidationButton && (
        <div className={cx(css.messageRequiredFields, css.messageRequiredButton)}>
          Пожалуйста, введите корректные данные
        </div>
      )}
    </Fragment>
  )
}

function renderTestOrderConfirmModal(state, props) {
  const {changeIsCommandant, changeOrderTest, billingInfo = {}} = props
  const {handleIsModalTestOrder} = state
  return (
    <ModalWrapper
      shouldUseOffset
      name={'testOrderConfirmModal'}
      // onCloseClick={toggleTimeSelectorModal(state, props)}
      titleCl={css.timeSelectorModalTitle}
      modalCl={css.timeSelectorModal}
    >
      <div className={css.testOrderText}>Уважаемый клиент! Ваш заказ включён в программу контроля качества. Поэтому с
        Вашего согласия, вместе с мастером на заказе возможно будут присутствовать два проверяющих эксперта. В этом
        случае Вы получите {billingInfo.BILLING_ORDER_WITH_COMMANDANT_DISCOUNT}% скидку от стоимости заказа. Вы
        согласны?
      </div>
      <Button
        theme={'green'}
        className={css.testOrderButton}
        onClick={() => {
          changeIsCommandant(true)
          handleIsModalTestOrder(false)
        }}
      >
        Хорошо
      </Button>
      <Button
        theme={'orange'}
        className={css.testOrderButton}
        onClick={() => {
          changeIsCommandant(false)
          handleIsModalTestOrder(false)
          changeOrderTest(false)
        }}
      >
        Отказаться
      </Button>
    </ModalWrapper>
  )
}

export default function NewOrderPage(props) {
  const {
    authReset,
    imageUrls,
    getMasterGeoMarkers,
    updateListStreet,
    masterMarkers,
    isCommandant,
    orderTest,
    newOrderLabels = {},
    orderBillingInfo,
    newOrderSlug: slug,
  } = props

  const mapProvider = PROVIDER_YANDEX

  const [orderState, orderHandlers] = useOrder(props)
  const state = {...orderState, ...orderHandlers}

  const [billingInfo, setBillingInfo] = useState({})

  useEffect(() => {
    orderBillingInfo({scope: slug}).then(
        (resp) => {
          setBillingInfo(resp)
        },
        () => {},
    )
  }, [])

  const initialBillingInput = {
    estimatedTime: 0,
    estimatedTimeInput: 0,
    employeeCount: 0,
    employeeCountInput: 0,
    orderType: "normal",
  }

  const [billingInput, setBillingInput] = useState(initialBillingInput)
  const [orderBilling, setOrderBilling] = useState({})

  useEffect(() => {
    const {
      estimatedTime,
      employeeCount,
      orderType
    } = billingInput

    const res = calcCost(0, estimatedTime, employeeCount, orderType, billingInfo)

    setOrderBilling(res)
  }, [billingInput, billingInfo])

  const toNum = (val) => {
    const parsed = parseFloat(val)
    return !isNaN(parsed) ? parsed : 0
  }

  const {
    isModalOpened,
    isAgree,
    isMap,
    isLoading,
    wasCodeSent,
    handleIsAgree,
    handleAddressError,
    isAuthRequestDisabled,
    isListStreet,
    isLogged,
    isValidationButton,
    orderMode,
    isAgreeError,
    isEmailError,
    emailError,
    isModalTestOrder,
    handleIsModalTestOrder,
    mastersText,
  } = state

  const onMapUpdated = (ctx) => {
    (masterMarkers || []).forEach((master) => {
      const masterMarker = ctx.placedMasterMarkers.get(master.id)

      if (masterMarker) {
        const hintContent = `<div id="content">
                             <div id="siteNotice"></div>
                             <div id="bodyContent">
                              <h4 style="margin: 0 0 6px 0">Мастер</h4>
                              <p style="margin: 0 0 6px 0">ID: ${master.id}</p>
                             </div>
                            </div>`;
        const _specs = (master.full_specs || []).reduce((prevVal, currVal) => `${prevVal}${currVal.type}<br/>`, '')
        const balloonContent = `<div id="content">
                                 <div id="siteNotice"></div>
                                 <div id="bodyContent">
                                    <h4 style="margin: 0 0 6px 0">Мастер</h4>
                                    <p style="margin: 0 0 6px 0">ID: ${master.id}</p>
                                    <h4 style="margin: 0 0 6px 0">Специализации</h4>
                                    <div style="max-height: 100px; overflow-y: auto;">
                                    ${_specs}
                                    </div>
                                 </div>
                                </div>`;

        if (mapProvider === PROVIDER_GOOGLE) {
          if (!masterMarker.masterInfoWindow) {
            masterMarker.masterInfoWindow = new google.maps.InfoWindow({
              content: hintContent,
            })

            masterMarker.addListener('mouseover', () => {
              masterMarker.masterInfoWindow.open({
                anchor: masterMarker,
                map: ctx.map,
                shouldFocus: false,
              })
            })

            masterMarker.addListener('mouseout', () => {
              masterMarker.masterInfoWindow.close()
            })
          }

          if (masterMarker.masterSpecsWindow) {
            masterMarker.masterSpecsWindow.setContent(balloonContent)
          } else {
            masterMarker.masterSpecsWindow = new google.maps.InfoWindow({
              content: balloonContent
            })

            masterMarker.addListener('click', () => {
              masterMarker.masterInfoWindow.close()

              masterMarker.masterSpecsWindow.open({
                anchor: masterMarker,
                map: ctx.map,
                shouldFocus: false,
              })
            })
          }
        }

        if (mapProvider === PROVIDER_YANDEX) {
          masterMarker.properties.set('hintContent', hintContent)
          masterMarker.properties.set('balloonContent', balloonContent)
        }

      }

      ctx.placedMasterMarkers.set(master.id, masterMarker)
    })
  }

  return (
    <Fragment>
      <div className={css.subtitleSmall}>{newOrderLabels.head_text.plain || mastersText}</div>
      <div className={css.subtitleSmall} dangerouslySetInnerHTML={{__html: newOrderLabels.head_sub_text.html || 'Они показаны на карте. Заполните,&nbsp;пожалуйста,&nbsp;все поля ниже, чтобы&nbsp;один из них мог принять Ваш заказ.' }}/>
      <div className={css.subtitleSub} dangerouslySetInnerHTML={{__html: newOrderLabels.head_sub_text_small.html || 'Вы увидите на карте, как мастер отправится по Вашему адресу. Квалификация&nbsp;и документы мастеров ЕЖЭК проверены. Рейтинг&nbsp;мастеров сформирован только из оценок заказчиков по&nbsp;итогам выполненных заказов.'}}/>

      <div className={css.helpBlock}>
        <Dialog
          render={({close}) => (
            <Fragment>
              <iframe
                height={"467"}
                width={"900"}
                src="https://www.youtube.com/embed/Kkvo2-WJutk"
                frameBorder="0"
                allowFullScreen
              />
              <Button onClick={() => close()}>
                { "Закрыть" }
              </Button>
              {/*<div className={css.videoModalClose} onClick={close}>*/}
              {/*  <Icon name="cross" size={24}/>*/}
              {/*</div>*/}
              {/*<div className={css.videoModalCloseText}>закрыть</div>*/}
            </Fragment>
          )}
          className={css.modalContent}
        >
          <Button
            className={css.howItWorkBtn}
            contentClassName={css.howItWorkBtnText}
          >
            { newOrderLabels.demoOrderButton || 'Как это работает' }
          </Button>
        </Dialog>

        <Dialog
          render={({close}) => (
              <Fragment>
                <div className={css.orderCalc}>
                  <div className={css.title}>
                    <h2>Калькулятор <br/> стоимости работ</h2>
                  </div>

                  <Input
                    className={css.wideInputWrap}
                    inputClassName={css.inputControl}
                    label={<p className={css.inputHelpText}>
                      По Вашей оценке, сколько часов займет работа?
                      <Dialog
                        className={css.modalContent}
                        render={({close}) => (
                          <Fragment>
                            <p>
                              Жилище каждого человека и потребности в ремонте или другой помощи по дому – сугубо индивидуальны. <br/>
                              Точно установить, сколько будет стоить работа, мастер сможет только по факту ее выполнения, <br/>
                              но, чтобы обеспечить Вам предсказуемость бюджета, мы разработали калькулятор для самостоятельного предварительного <br/>
                              рассчета стоимости исходя из планируемого времени выполнения работ. <br/>
                              Если Вы затрудняетесь самостаятельно оценить время выполнения работы, <br/>
                              позвоните нам <SmartLink to={"tel:+74951985495"} style={{marginLeft: "4px"}}>{'+7 (495) 1985 495'}</SmartLink>, наш специалист Вам поможет.<br/>
                              Оцените стоимость при помощи калькулятора и при желании укажите ее в разделе «Что необходимо сделать». <br/>
                              Мы готовы зафиксировать стоимость на указанном Вами уровне, если время, <br/>
                              затраченное мастером на работу по факту, не будет отличаться более чем на 20 минут.
                            </p>
                            <Button onClick={() => close()}>
                              { "Закрыть" }
                            </Button>
                          </Fragment>
                        )}
                      >
                        <span style={{
                          marginLeft: "4px",
                          display: "flex",
                          justifyContent: "center",
                        }}>
                          <InfoIcon
                            color={"#0495fd"}
                            size={"16"}
                          />
                        </span>
                      </Dialog>
                    </p>}
                    name={'estimatedTime'}
                    // isRequiredLabel
                    id={'estimatedTime'}
                    type="text"
                    // pattern="\d*"
                    // inputMode="decimal"
                    value={`${billingInput.estimatedTimeInput}`}
                    handleInput={(val) => setBillingInput({
                      ...billingInput,
                      estimatedTimeInput: val,
                      estimatedTime: toNum(val),
                    })}
                    // min={0}
                  />
                  <Input
                    className={css.wideInputWrap}
                    inputClassName={css.inputControl}
                    label={<p className={css.inputHelpText}>
                      Сколько нужно мастеров?
                      <Dialog
                        className={css.modalContent}
                        render={({close}) => (
                          <Fragment>
                            <p>
                              Если по Вашему мнению работа требует участия нескольких мастеров, <br/>
                              то каждого из них необходимо вызвать в обычном порядке <br/>
                              на отдельный срочный или предварительный заказ и оплатить отдельно. <br/>
                              Предварительный рассчет стоимости делается для одного <br/>
                              мастера и дополнительно умножается на количество мастеров.
                            </p>
                            <Button onClick={() => close()}>
                              { "Закрыть" }
                            </Button>
                          </Fragment>
                        )}
                      >
                        <span style={{
                          marginLeft: "4px",
                          display: "flex",
                          justifyContent: "center",
                        }}>
                          <InfoIcon
                            color={"#0495fd"}
                            size={"16"}
                          />
                        </span>
                      </Dialog>
                    </p>}
                    name={'employeeCount'}
                    // isRequiredLabel
                    id={'employeeCount'}
                    type="text"
                    // pattern="\d*"
                    // inputMode="decimal"
                    value={`${billingInput.employeeCountInput}`}
                    handleInput={(val) => setBillingInput({
                      ...billingInput,
                      employeeCount: toNum(val),
                      employeeCountInput: val,
                    })}
                    // min={0}
                  />

                  <fieldset style={{display: "none"}}>
                    <legend>
                      <div className={css.labelRequired}>{"* "}</div>
                      Тип заказа
                    </legend>

                    <div className={css.inputRadioSet}>
                      <div className={css.inputRadio}>
                        <label htmlFor="orderTypeHot">
                          Срочный
                        </label>
                        <input
                            checked={billingInput.orderType === "hot"}
                            id="orderTypeHot"
                            name="orderType"
                            type="radio"
                            value={"hot"}
                            onChange={(el) => setBillingInput({...billingInput, orderType: el.target.value})}
                        />
                      </div>
                      <div className={css.inputRadio}>
                        <label htmlFor="orderTypeNormal">
                          Предварительный
                        </label>
                        <input
                            checked={billingInput.orderType === "normal"}
                            id="orderTypeNormal"
                            name="orderType"
                            type="radio"
                            value={"normal"}
                            onChange={(el) => setBillingInput({...billingInput, orderType: el.target.value})}
                        />
                      </div>
                    </div>
                  </fieldset>

                  <div>
                    <h3 className={css.inputHelpText}>
                      Предварительная оценка стоимости
                      <Dialog
                        className={css.modalContent}
                        render={({close}) => (
                          <Fragment>
                            <p>
                              Данная сумма является базовой и может измениться как в большую, <br/>
                              так и в меньшую сторону, если работа займёт больше или меньше времени, чем Вы предполагаете. <br/>
                              По статистике ЕЖЭК такое отклонение обычно не превышает 20% от базовой суммы. <br/>
                              Базовая стоимость не включает надбавку за поход за материалами и за удалённость от метро. <br/>
                              Финальная стоимость заказа будет увеличена на эти надбавки, если они будут применимы. <br/>
                              Более подробно с порядком расчета стоимости Вы можете ознакомиться в Кодексе ЕЖЭК. <br/>
                              Если у Вас остались вопросы, позвоните нам
                              <SmartLink to={"tel:+74951985495"} style={{marginLeft: "4px"}}>{'+7 (495) 1985 495'}</SmartLink>
                            </p>
                            <Button onClick={() => close()}>
                              { "Закрыть" }
                            </Button>
                          </Fragment>
                        )}
                      >
                        <span style={{
                          marginLeft: "4px",
                          display: "flex",
                          justifyContent: "center",
                        }}>
                          <InfoIcon
                            color={"#0495fd"}
                            size={"16"}
                          />
                        </span>
                      </Dialog>
                    </h3>
                    <AccordionGroup>
                      <Accordion
                        label={getPriceFormatted(orderBilling.totalAmount, 2)}
                        content={(
                          <table>
                            <tbody>
                              <tr>
                                <td>Кол-во мастеров</td>
                                <td align="right">{orderBilling.employeeCount}</td>
                              </tr>
                              <tr>
                                <td>Время работы (минуты)</td>
                                <td align="right">{orderBilling.totalMinutes}</td>
                              </tr>
                              {/*<tr>*/}
                              {/*  <td>Бесплатное время (минуты)</td>*/}
                              {/*  <td align="right">{orderBilling.freeMinutes}</td>*/}
                              {/*</tr>*/}
                              {/*<tr>*/}
                              {/*  <td>Первоначальный платёж</td>*/}
                              {/*  <td align="right">{getPriceFormatted(orderBilling.initialAmount, 2)}</td>*/}
                              {/*</tr>*/}
                              <tr>
                                <td>Минимальный заказ</td>
                                <td align="right">{getPriceFormatted(orderBilling.minAmount, 2)}</td>
                              </tr>
                              <tr>
                                <td>Стоимость работ (цена за 1 минуту)</td>
                                <td align="right">{getPriceFormatted(orderBilling.pricePerMinute, 2)}</td>
                              </tr>
                              <tr>
                                <td>Сумма</td>
                                <td align="right">{getPriceFormatted(orderBilling.subTotalAmount, 2)}</td>
                              </tr>
                              <tr>
                                <td>Скидка</td>
                                <td align="right">{getPriceFormatted(orderBilling.discountAmount, 2)}</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td>Итого</td>
                                <td align="right">
                                  {orderBilling.employeeCount > 1 ? `x${orderBilling.employeeCount} = ` : ""}
                                  {getPriceFormatted(orderBilling.totalAmount, 2)}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        )}
                      />
                    </AccordionGroup>
                  </div>

                  <Button onClick={() => close()}>
                    { "Закрыть" }
                  </Button>
                </div>
              </Fragment>
          )}
          className={css.modalContent}
        >
          <Button
            className={css.howItWorkBtn}
            contentClassName={css.howItWorkBtnText}
          >
            Сколько это стоит
          </Button>
        </Dialog>
      </div>

      <div className={css.subtitleBlock}>
        <InfoMessage message={'Ваш адрес'} iconName={'location'} className={cx(css.subtitle, css.address)}/>
        <SmartLink isUnderlined onClick={handleMapVisibility(state, props)} className={css.mapControlButton}>
          {isMap ? 'Спрятать карту' : 'Указать на карте'}
        </SmartLink>
      </div>

      <UnifiedMap
        provider={PROVIDER_YANDEX}
        className={cx(css.map, {[css.disabled]: !isMap})}
        searchText={state['searchText']}
        passAddress={handleAddress(state)}
        changeStreetList={updateListStreet}
        masterMarkers={masterMarkers}
        handleAddressError={handleAddressError}
        isInputActive={isListStreet}
        infoWindowDisabled={true}
        onMapUpdated={onMapUpdated}
      />
      {renderInputFormAddress(state, props)}

      <InfoMessage message={'Что необходимо сделать'} iconName={'settings'} className={css.subtitle}/>
      <Input
        textArea
        className={css.textareaWrap}
        inputClassName={css.textarea}
        element={'textarea'}
        name={'description'}
        {...getControlProps('description', state)}
        id={'description'}
      />
      {/*<div className={css.messageRequiredFields}>* — обязательно к заполнению</div>*/}

      <div className={css.attachment}>
        <Attachment
          isLoading={state['isImageLoading']}
          label={'Прикрепите фотографию или видео'}
          files={(imageUrls || []).filter(Boolean)}
          handleFiles={uploadFiles(state, props)}
          isDeleteImageIcon={true}
        />
      </div>

      <InfoMessage
        message={'Укажите время вызова'}
        shouldShowCircle={false}
        iconName={'time'}
        size={30}
        className={cx(css.subtitle, css.callTimeSubtitle)}
        iconCl={css.callTimeIcon}
      />

      {renderOrderModeSection(state, props)}

      {isModalOpened && renderTimeSelectorModal(state, props)}
      {isModalTestOrder && renderTestOrderConfirmModal(state, props)}
      <div className={css.priceBlock}>
        <div><SmartLink theme={'orange'} isUnderlined to={'/codex/tariffs'} className={css.privacyLink}>
          Вызов мастера БЕСПЛАТНО.&nbsp;
          </SmartLink></div>
        { (orderMode == 'time' && (<div dangerouslySetInnerHTML={{__html: newOrderLabels.body_sub_text_b.html}}/>))
          || (orderMode != 'hot' && (<div dangerouslySetInnerHTML={{__html: newOrderLabels.body_sub_text_a.html}}/>))
          || <div><br /> Мастер на час - {' '}
            <span className={css.price}>
              {orderMode === 'time' ? '1500' : '1500'}
            </span>
            <Icon name={'rouble'} size={30} className={css.roubleIcon}/>
            <br /> Мастер на пол-дня - {' '}
            <span className={css.price}>
              {orderMode === 'time' ? '5400' : '5400'}
            </span>
            <Icon name={'rouble'} size={30} className={css.roubleIcon}/>
            <br /> Мастер на день - {' '}
            <span className={css.price}>
              {orderMode === 'time' ? '9600' : '9600'}
            </span>
            <Icon name={'rouble'} size={30} className={css.roubleIcon}/>
            </div>
        }

        {orderMode === 'time'
          ? <div className={css.noticeOrange} dangerouslySetInnerHTML={{__html: newOrderLabels.body_text_b.html || ``}}/>
          : <div className={css.noticeOrange} dangerouslySetInnerHTML={{__html: newOrderLabels.body_text_a.html || ``}}/>}
          <Fragment>
            <font size="2" color="#A0A0A0" face="roboto"> &nbsp;
            <br />Чтобы рассчитать ориентировочную стоимость работ, <br />перейдите в калькулятор, нажав кнопку <br />"сколько это стоит".<br />Оплата за работу мастера происходит только <br />ПОСЛЕ выполнения заказа путём списания <br />расчётной суммы с Вашей банковской карты.<br />Для привязки Вашей карты к заказу <br />мы спишем и вернём 1 руб. <br />Для отмены привязки карты заполните &nbsp;
            <SmartLink theme={'orange'} isUnderlined to={'/order_terminate'} className={css.privacyLink}>
              форму&nbsp; 
            </SmartLink>
            </font>
          </Fragment>
      </div>

      <div className={css.credentials}>
        <Input
          className={css.inputWrap}
          inputClassName={css.input}
          label={'Имя'}
          name={'firstName'}
          isRequiredLabel
          {...getControlProps('firstName', state)}
          id={'firstName'}
        />
        <Input
          className={css.inputWrap}
          inputClassName={css.input}
          label={'Фамилия'}
          name={'lastName'}
          {...getControlProps('lastName', state)}
        />
      </div>

      <Input
        disabled={isLogged}
        className={css.wideInputWrap}
        inputClassName={css.wideInput}
        placeholder={phonePlaceholder}
        handleInput={handleInput('phone', state)}
        value={state['phone']}
        error={state[`phoneError`]}
        label={'Телефон'}
        name={'phone'}
        isRequiredLabel
        id={'phone'}
      />

      <Input
        className={cx(css.wideInputWrap, css.emailWrap)}
        inputClassName={css.wideInput}
        label={'Электронная почта для чека'}
        name={'email'}
        id={'email'}
        {...getControlProps('email', state)}
      />
      <div className={css.divResetPhoneContainer}>
        <div className={css.messageRequiredFields}>* — обязательно к заполнению</div>
        {isLogged && (
          <div className={css.divResetPhone} onClick={() => {
            authReset()
            state[`handlePhone`]('')
            recordLocalStorage({...state, phone: ""})
          }}>указать другой номер</div>
        )}
      </div>

      <Checkbox
        name={'isAgreeWithTerms'}
        checked={isAgree}
        handleChange={handleIsAgree}
        className={css.privacyCheckbox}
        disabled={wasCodeSent}
        label={
          <Fragment>
            Я согласен с&nbsp;
            <SmartLink theme={'dark'} isUnderlined to={'/codex'} className={css.privacyLink}>
              общими условиями деятельности&nbsp;
            </SmartLink>
            Сервиса ЕЖЭК и на обработку моих персональных данных
          </Fragment>
        }
        {...getControlProps('isAgree', state)}
      />

      {!isLogged && (
        <Fragment>
          <Button
            theme={'green'}
            className={css.sendCodeButton}
            disabled={wasCodeSent}
            // disabled={wasCodeSent || GLOBALS.ENV_PRESET === 'production'}
            // style={GLOBALS.ENV_PRESET === 'production' ? {pointerEvents: "none"} : {}}
            onClick={handleAuthRequest(state, props)}
            isBusy={!wasCodeSent && isLoading}
          >
            Получить код
          </Button>

          {isValidationButton && (
            <div className={cx(css.messageRequiredFields, css.messageRequiredButton)}>
              Пожалуйста, введите корректные данные
            </div>
          )}
          {!!emailError && (
            <div className={cx(css.messageRequiredFields, css.messageRequiredButton)}>
              {emailError}
            </div>
          )}
        </Fragment>
      )}


      {orderTest && !wasCodeSent && !isLogged && (
        <Checkbox
          name={'isCommandant'}
          checked={isCommandant}
          handleChange={() => handleIsModalTestOrder(true)}
          className={css.testOrderCheckbox}
          //disabled={wasCodeSent}
          label={<div>Контрольный заказ</div>}
        />
      )}

      {(wasCodeSent || isLogged) && renderWasCodeSentSection(state, props)}

      {isAgreeError && !isAgree && (
        <div className={cx(css.messageRequiredFields, css.messageRequiredButton)}>
          Для того, чтобы продолжить, необходимо согласие с общими условиям деятельности и на обработку персональных
          данных.
        </div>
      )}

      <Attention className={css.attention}>
        {wasCodeSent || isLogged
          ? (orderMode === 'time'
            ? newOrderLabels.warning_text_b.plain || 'Вы будете перенаправлены на страницу резервирования средств. Предоплата не взимается. Деньги будут списаны только после выполнения заказа. Бесплатная отмена за час до назначенного времени выполнения заказа.'
            : newOrderLabels.warning_text_a.plain || 'Вы будете перенаправлены на страницу резервирования средств. Предоплата не взимается. Деньги будут списаны только после выполнения заказа.')
          : 'На ваш номер будет отправлено СМС с кодом подтверждения'}
      </Attention>

      <img src={sberbank} className={css.sberLogo}/>

      {/*<div className={css.tariffsLinkWrap}>*/}
      {/*  <SmartLink isUnderlined to={'/'} theme={'orange'} className={css.tariffsLink}>*/}
      {/*    Ознакомиться с тарифами*/}
      {/*  </SmartLink>*/}
      {/*</div>*/}
    </Fragment>
  )
}

NewOrderPage.propTypes = {
  className: T.string,
  title: T.string,
  newOrderTitle: T.string,
  newOrderType: T.string,
  id: T.number,
  description: T.string,
  phone: T.string,
  imageUrls: T.array,

  price: T.number,

  changeDescription: T.func,
  changePhone: T.func,
  changeImageUrls: T.func,
  changeTime: T.func,

  requestAuth: T.func,
  confirmAuth: T.func,
  placeOrder: T.func,
  updateOrder: T.func,
  updateListStreet: T.func,

  confirmationCode: T.string,
  authToken: T.string,
}
NewOrderPage.defaultProps = {
  className: '',
  title: '',
}
