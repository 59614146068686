import React, {useState} from 'react'

import isEmpty from 'lodash/isEmpty'

import pageConnector from 'app/Pages/utils/pageConnector'
import {selectOrderField} from 'flux/selectors/order'

import {toPhonePattern} from 'helpers/form/mask'

import Footer from 'app/Elements/Footer'
import SmartLink from 'app/Elements/SmartLink'
import Input from 'app/Elements/Input'
import Button from 'app/Elements/Button'
import Map from 'app/Elements/Map'

import cx from 'classnames'
import css from './index.styl'

function Contacts(props) {
  const {
    client_metadata: {
      service_phone,
      service_email,
      service_address,
    } = {}
  } = props

  const _formFields = {
    firstName: '',
    firstNameError: '',
    email: '',
    emailError: '',
    topic: '',
    message: '',
    messageError: '',
  }

  const [isLoading, handleIsLoading] = useState(false)
  const [formFields, handleFormFields] = useState(_formFields)

  function clearFormFields() {
    handleFormFields(_formFields)
  }

  function checkFields() {
    let errors = {}

    if (!formFields.firstName) {
      errors.firstNameError = 'Введите имя'
    }

    if (!formFields.email) {
      errors.emailError = 'Введите email'
    }

    if (!formFields.message) {
      errors.messageError = 'Введите сообщение'
    }

    if (!isEmpty(errors)) {
      handleErrors(errors)
    }

    return isEmpty(errors)
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (checkFields()) {
      handleIsLoading(true)

      setTimeout(() => {
        clearFormFields()
        handleIsLoading(false)
      }, 2000)
    }
  }

  function handleErrors(errors) {
    handleFormFields({
      ...formFields,
      ...errors,
    })
  }

  function handleInput(value, field, isError) {
    handleFormFields({
      ...formFields,
      [field]: !isError ? value : formFields[field],
      [`${field}Error`]: isError ? value : '',
    })
  }

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <div className={css.leftPart}>
          <div className={css.info}>
            <SmartLink to={`tel:+${service_phone}`} className={css.infoText}>{toPhonePattern(service_phone)}</SmartLink>
            <SmartLink to={`mailto:${service_email}`} className={css.infoText}>{service_email}</SmartLink>
            <div className={css.infoText}>{'ООО "ЕЖЭК", ИНН 7718966530'}</div>
            <div className={css.infoText}>{'ОГРН 1147746089644'}</div>
            <div className={css.infoText}>{service_address}</div>
          </div>
          <div className={css.mapWrap}>
            <Map
              className={css.map}
              actionsDisabled={true}
              markerDisabled={true}
              startCoordinat={{lat: 55.795641, lng: 37.699292}}
              zoom={17}
              infoWindowDisabled={true}
              forContacts
            />
          </div>
        </div>
        <div className={css.rightPart}>
          <div className={cx(css.inputLabel, css.infoText)}>Остались вопросы?</div>
          <form onSubmit={handleSubmit} className={css.form}>
            <div className={css.inputRow}>
              <div className={css.inputLabel}>Имя *</div>
              <Input
                className={css.inputWrap}
                inputClassName={css.input}
                handleInput={(value) => handleInput(value, 'firstName')}
                value={formFields.firstName}
                name={'firstName'}
                id={'firstName'}
                disabled={isLoading}
              />
              <div className={css.inputError}>{formFields.firstNameError}</div>
            </div>
            <div className={css.inputRow}>
              <div className={css.inputLabel}>Email *</div>
              <Input
                className={css.inputWrap}
                inputClassName={css.input}
                handleInput={(value) => handleInput(value, 'email')}
                value={formFields.email}
                name={'email'}
                id={'email'}
                disabled={isLoading}
              />
              <div className={css.inputError}>{formFields.emailError}</div>
            </div>
            <div className={css.inputRow}>
              <div className={css.inputLabel}>Тема</div>
              <Input
                className={css.inputWrap}
                inputClassName={css.input}
                handleInput={(value) => handleInput(value, 'topic')}
                value={formFields.topic}
                name={'topic'}
                id={'topic'}
                disabled={isLoading}
              />
            </div>
            <div className={css.inputRow}>
              <div className={css.inputLabel}>Сообщение *</div>
              <Input
                textArea
                className={css.inputWrap}
                inputClassName={cx(css.input, css.textarea)}
                handleInput={(value) => handleInput(value, 'message')}
                value={formFields.message}
                element={'textarea'}
                name={'message'}
                id={'message'}
                disabled={isLoading}
              />
              <div className={css.inputError}>{formFields.messageError}</div>
            </div>
            <Button
              theme={'green'}
              className={css.button}
              contentClassName={css.buttonText}
              isBusy={isLoading}
              type="submit"
            >
              Отправить
            </Button>
          </form>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

const mapStateToProps = (state) => ({
  client_metadata: selectOrderField('client_metadata', state),
})

export default pageConnector(Contacts, {
  mapStateToProps,
})
