import fetch from 'fetch'

export default ({ body, token }) => {
  return fetch({
    url: '/client/order/replace/',
    method: 'POST',
    body: { ...body },
    token,
  })
}
